@import "src/styles/variables.scss";

.siteLayout {
  background-color: #f3f3f3!important;
  overflow: hidden;
}
.logoWrapper {
  width: 100%;
  height: 88px;
  display: flex;
  & > a {
    margin: 0 auto;
  }
}
.logo {
  margin: 0 auto;
}
.logoCollapsed {
  padding-left: 0;
}
.sidebar {
  border-right: none;
}
.closeSider {
  & > div > ul > li > div {
    padding: 0 35px !important;
  }
}
.subTitle {
  & > div {
    padding: 0 16px !important;
  }
}
.noneEvents {
  pointer-events: none;
}

.navContainer {
  display: flex;
  align-items: center;
  height: 50px !important;
  margin: 0!important;
  padding-right: 0 !important;
  padding-left: 40px !important;
  .iconTable {
    flex: 0;
  }
  [class~='ant-image']  {
    filter: invert(0.8);
  }
}
.navContainer:hover {
  .iconTable {
    [class~='ant-image'] {
      filter: invert(1) !important;
    }
  }
}
.mainContent {
  background: #f3f3f3;
  overflow: auto;
}
.scrollMainContent {
  overflow-x: auto;
  overflow-y: hidden !important;
}
.siteLayoutBackground {
  padding: 0 !important;
  margin-top: 0!important;
  box-shadow: inset 0px -1px 0px #F0F0F0;
  height: 89px;
  position: relative;
  background-color: #fff;
}

.userNamePlacement {
  text-align: right;
  padding-right: 45px;
  font-size: 15px;
  margin-left: 10px;
  color: black;
}

.addAssessment {
  width: 100%;
  height: 32px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
  button {
    text-align: start;
    width: 100%;
    border: none;
    border-radius: 0;
    background-color: #1890ff;
    color: white;
    float: right;
    z-index: 4;
  }
}

.userMenu {
  position: relative;
  border-right: none;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15)!important;
  z-index: 3;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
}

.menu {
  position: relative;
  border-right: none;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15)!important;
  z-index: 3;
  height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.subMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.trigger {
  width: 100%;
  bottom: 0;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
  display: flex!important;
  align-items: center;
  position: absolute;
  left: 0;
  z-index: 4;
  & > span {
    color: #fff;
    padding: 14px 0 14px 16px;
  }
  & > span:hover {
    color: #1890ff!important;
  }
}

.settingsMenu {
  float: right;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
}
.ant-layout-sider-trigger {
  width: 205px!important;
  z-index: 3!important;
  box-shadow: inset 0 1px 0 #F0F0F0;
  &:hover {
    color: #1890ff;
  }
}

.img {
  height: 35px;
  width: 35px;
  margin: 10px;
}
